window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

document.addEventListener('DOMContentLoaded', function() {
    // Add a click event listener to the "Request a sponsorship" link
    document.getElementById('requestSponsorship').addEventListener('click', function() {
        // Trigger Heyflow button click action to go to the next page
        window.heyflow['portal2'].buttonClick(this, 'go', 'id-d197c251', '{"redirectURL":"https://heyflow.com","labelClass":"align-left","containerClass":"inner-narrow","destination":"id-d197c251","noValidate":true,"label":"Request&nbsp;a&nbsp;sponsorship.","nextAction":"next","type":"generic-button","tracking":true,"target":"_self","paddingBottom":20,"name":"button-link","contentClass":"align-space-between","action":"go","eventName":"Request_a_sponsorship","id":"id-9a89da6b","paddingTop":0,"class":"link&nbsp;collapse&nbsp;large&nbsp;align-left","showLine2":false,"line2":""}');
    });
});
